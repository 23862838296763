import React from "react";
import { Box, AppBar, Toolbar, Link, Typography, Button } from "@mui/material";

function Navbar() {
  const handleWhatsAppClick = () => {
    // Reemplaza 'tu-enlace-de-whatsapp' con el enlace real de WhatsApp
    window.location.href = "https://wa.me/message/HOYXZTTFNXNZH1";
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ boxShadow: "none" }} position="static" color="transparent">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            <Link style={{ textDecoration: "none", color: "#FF5733" }} to="/">
              <Typography style={{ fontWeight: "bold" }}>
                WebProfitDesign
              </Typography>
            </Link>
          </Typography>
          <Button
            sx={{
              display: {
                xs: "none",
                sm: "block",
                md: "block",
                lg: "flex",
                xl: "flex",
              },
            }}
            size="large"
            color="success"
            variant="text"
            onClick={handleWhatsAppClick}
          >
            Contactanos
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;
