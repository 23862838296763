import { Box, Button, Grid, Typography } from "@mui/material";
import "../styles/homepage.css";

import { Link } from "react-router-dom";

import Iframe from "react-iframe";

import Section from "../components/Section";
import SectionResponsive from "../components/SectionResponsive";

import { CardTestimonial } from "../components/CardTestimonial";

import { SectionTestimonial } from "../components/SectionTestimonial";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Fab } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

function HomePage() {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleWhatsAppClick = () => {
    // Reemplaza 'tu-enlace-de-whatsapp' con el enlace real de WhatsApp
    window.location.href = "https://wa.me/message/HOYXZTTFNXNZH1";
  };
  const handlecalendar = () => {
    // Reemplaza 'tu-enlace-de-whatsapp' con el enlace real de WhatsApp
    window.location.href = "https://calendar.app.google/tdG4zFKXJM2yPcX89";
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Grid container xs={12} sm={12}>
      <Fab
        size="large"
        color="success"
        aria-label="whatsapp"
        style={{ position: "fixed", bottom: 20, right: 20 }}
        onClick={handleWhatsAppClick}
      >
        <WhatsAppIcon />
      </Fab>
      <Box className="containerHomePage">
        <Box className="background">
          <Grid item className="hero1" xs={12} sm={6}>
            <Box className="backgroundHero1">
              <Box className="containerItemshero1">
                <Typography
                  sx={{
                    marginBottom: {
                      xs: "4vh",
                      sm: "10vh",
                      md: "10vh",
                      lg: "10vh",
                      xl: "10vh",
                    },
                    marginTop: {
                      xs: "4vh",
                      sm: "5vh",
                      md: "15vh",
                      lg: "10vh",
                      xl: "10vh",
                    },
                    marginLeft: {
                      xs: "2vh",
                      sm: "10vh",
                      md: "15vh",
                      lg: "15vh",
                      xl: "20vh",
                    },
                    marginRight: {
                      xs: "2vh",
                      sm: "0px",
                      md: "15vh",
                      lg: "15vh",
                      xl: "20vh",
                    },
                    fontSize: {
                      xs: "2rem",
                      sm: "2rem",
                      md: "3rem",
                      lg: "3rem",
                      xl: "4rem",
                    },
                  }}
                  color={"#2C3E50"}
                >
                  ¡Descubre Cómo {""}
                  <span style={{ fontWeight: "bold" }}>
                    Impulsar tu Negocio{" "}
                  </span>
                  en la Era Digital!
                </Typography>

                <Button
                  onClick={() => scrollToElement("read")}
                  variant="contained"
                  color="primary"
                  size="medium"
                  style={{ borderRadius: "20px" }}
                  sx={{
                    marginBottom: {
                      xs: "5vh",
                      sm: "6vh",
                      md: "7vh",
                      lg: "7vh",
                      xl: "20vh",
                    },
                    marginLeft: {
                      xs: "0px",
                      sm: "0vh",
                      md: "0vh",
                      lg: "0px",
                      xl: "0px",
                    },
                    marginRight: {
                      xs: "0px",
                      sm: "0vh",
                      md: "0px",
                      lg: "0px",
                      xl: "0px",
                    },
                    fontSize: {
                      xs: "1",
                      sm: ".7rem",
                      md: "1rem",
                      lg: "1rem",
                      xl: "1rem",
                    },
                  }}
                >
                  Saber Mas
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item className="hero1-2" xs={12} sm={6}>
            <Box className="image1" />
          </Grid>
        </Box>
      </Box>

      <Grid item className="hero1" xs={12} sm={6}>
        <Box className="backgroundHero1">
          <Box className="containerItemshero2">
            <Typography
              id="read"
              sx={{
                marginBottom: {
                  xs: "2vh",
                  sm: "3vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "10vh",
                },

                marginLeft: {
                  xs: "2vh",
                  sm: "0vh",
                  md: "0vh",
                  lg: "0vh",
                  xl: "0vh",
                },
                marginRight: {
                  xs: "1vh",
                  sm: "0px",
                  md: "0px",
                  lg: "0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "2.4rem",
                  sm: "1.7rem",
                  md: "2.4rem",
                  lg: "2.4rem",
                  xl: "4rem",
                },
              }}
              variant="h4"
              color={"#2C3E50"}
            >
              ¿Como?
            </Typography>
            <Box>
              <Box>
                <iframe
                  width="100%"
                  height="350vh"
                  src="https://www.youtube.com/embed/kplXQUMm1wk"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </Box>
              <Box>
                <Button
                  onClick={handleWhatsAppClick}
                  variant="contained"
                  color="primary"
                  size="medium"
                  style={{ borderRadius: "20px" }}
                  sx={{
                    marginBottom: {
                      xs: "2vh",
                      sm: "2vh",
                      md: "7vh",
                      lg: "25vh",
                      xl: "15vh",
                    },
                    marginTop: {
                      xs: "2vh",
                      sm: "2vh",
                      md: "2vh",
                      lg: "10vh",
                      xl: "5vh",
                    },
                    fontSize: {
                      xs: "1",
                      sm: ".7rem",
                      md: "1rem",
                      lg: "1rem",
                      xl: "1.5rem",
                    },
                  }}
                >
                  Contactanos{" - "}
                  <WhatsAppIcon />
                  {" - "}
                </Button>
                <Button
                  onClick={handlecalendar}
                  variant="contained"
                  color="primary"
                  size="medium"
                  style={{ borderRadius: "20px" }}
                  sx={{
                    marginBottom: {
                      xs: "2vh",
                      sm: "2vh",
                      md: "7vh",
                      lg: "25vh",
                      xl: "15vh",
                    },
                    marginTop: {
                      xs: "2vh",
                      sm: "2vh",
                      md: "2vh",
                      lg: "10vh",
                      xl: "5vh",
                    },
                    marginLeft: {
                      xs: "2vh",
                      sm: "2vh",
                      md: "2vh",
                      lg: "2vh",
                      xl: "2vh",
                    },
                    fontSize: {
                      xs: "1",
                      sm: ".7rem",
                      md: "1rem",
                      lg: "1rem",
                      xl: "1.5rem",
                    },
                  }}
                >
                  agenda una cita
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item className="hero1-2" xs={"none"} sm={6}>
        <Box className="image2" />
      </Grid>

      <Grid item className="hero1" xs={12} sm={6}>
        <Box className="backgroundHero1">
          <Box className="containerItemshero2">
            <Typography
              sx={{
                marginBottom: {
                  xs: "2vh",
                  sm: "3vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "2vh",
                },
                marginTop: {
                  xs: "2vh",
                  sm: "3vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "10vh",
                },
                marginLeft: {
                  xs: "2vh",
                  sm: "0vh",
                  md: "0vh",
                  lg: "0vh",
                  xl: "0vh",
                },
                marginRight: {
                  xs: "1vh",
                  sm: "0px",
                  md: "0px",
                  lg: "0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "2.4rem",
                  sm: "1.7rem",
                  md: "2.4rem",
                  lg: "2.4rem",
                  xl: "4rem",
                },
              }}
              variant="h4"
              color={"#2C3E50"}
            >
              Empeza a asesorarte ya mismo
            </Typography>
            <Typography
              sx={{
                marginBottom: {
                  xs: "2vh",
                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "2vh",
                },
                marginTop: {
                  xs: "2vh",
                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "2vh",
                },
                marginLeft: {
                  xs: "2vh",
                  sm: "0vh",
                  md: "0vh",
                  lg: "0vh",
                  xl: "0px",
                },
                marginRight: {
                  xs: "2vh",

                  sm: "0vh",
                  md: "0px",
                  lg: "0px",
                  xl: "0px",
                },
                fontSize: {
                  xs: "1.5rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1rem",
                  xl: "2rem",
                },
              }}
              color={"#2C3E50"}
            >
              <span style={{ fontWeight: "bold" }}>3 Sencillos Pasos {""}</span>
            </Typography>
            <Typography
              sx={{
                marginBottom: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "2vh",
                },
                marginTop: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "2vh",
                },
                marginLeft: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "7vh",
                },
                marginRight: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "7vh",
                },
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
              }}
              variant="h4"
              color={"#2C3E50"}
            >
              Nuestro equipo de expertos te asesorará y te acompañará durante
              todo el proceso para lograr los mejores resultados.
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item className="hero1-2" xs={12} sm={6}>
        <Box className="steps">
          <Box className="stepsContainer">
            <Typography
              sx={{
                marginBottom: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "2vh",
                },
                marginTop: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "2vh",
                },
                marginLeft: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "7vh",
                },
                marginRight: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "7vh",
                },
                fontSize: {
                  xs: "1.5rem",
                  sm: "1.5rem",
                  md: "1.5rem",
                  lg: "1.5rem",
                  xl: "2rem",
                },
              }}
              color={"whitesmoke"}
            >
              <span style={{ fontWeight: "bold" }}>
                Agenda una reunion o Contactanos... {""}
              </span>
            </Typography>
          </Box>
          <Box className="stepsContainer2">
            <Typography
              sx={{
                marginBottom: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "2vh",
                },
                marginTop: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "2vh",
                },
                marginLeft: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "7vh",
                },
                marginRight: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "7vh",
                },
                fontSize: {
                  xs: "1.5rem",
                  sm: "1.5rem",
                  md: "1.5rem",
                  lg: "1.5rem",
                  xl: "2rem",
                },
              }}
              variant="h4"
              color={"whitesmoke"}
            >
              <span style={{ fontWeight: "bold" }}>
                Analicemos como podemos mejorar tus ventas juntos con un
                asesoramiento a tu medida {""}
              </span>
            </Typography>
          </Box>
          <Box className="stepsContainer">
            <Typography
              sx={{
                marginBottom: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "2vh",
                },
                marginTop: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "2vh",
                },
                marginLeft: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "7vh",
                },
                marginRight: {
                  xs: "2vh",

                  sm: "2vh",
                  md: "2vh",
                  lg: "2vh",
                  xl: "7vh",
                },
                fontSize: {
                  xs: "1.5rem",
                  sm: "1.5rem",
                  md: "1.5rem",
                  lg: "1.5rem",
                  xl: "2rem",
                },
              }}
              variant="h4"
              color={"whitesmoke"}
            >
              <span style={{ fontWeight: "bold" }}>
                Solo sientate y espera a que los clientes vengan solos! {""}
              </span>
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={0}
        lg={0}
        xl={0}
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <Section />
      </Grid>
      <Grid
        item
        xs={0}
        sm={0}
        md={12}
        lg={12}
        xl={12}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <SectionResponsive />
      </Grid>

      <Grid item xs={12}>
        <Box></Box>
      </Grid>
      <Grid
        item
        xs={0}
        sm={0}
        md={0}
        lg={12}
        xl={12}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
            xl: "block",
          },
        }}
      ></Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={0}
        xl={0}
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "block",
            lg: "none",
            xl: "none",
          },
        }}
      ></Grid>
    </Grid>
  );
}

export default HomePage;
