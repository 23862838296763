import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Link,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Grid,
  Divider,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import VisaIcon from "@mui/icons-material/CreditCard"; // You may need to import an appropriate icon
import MasterCardIcon from "@mui/icons-material/CreditCard"; // You may need to import an appropriate icon
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

import "../styles/footer.css";

function Footer() {
  const handleWhatsAppClick = () => {
    // Reemplaza 'tu-enlace-de-whatsapp' con el enlace real de WhatsApp
    window.location.href = "https://wa.me/message/HOYXZTTFNXNZH1";
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          backgroundColor: "#F35A3A",
          color: "#fff",
          padding: "2rem",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Ayuda</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {[
                  "Eventos",
                  "Compra tu Web",
                  "Contratar Servicio",
                  "Contacto",
                  "Preguntas Frecuentes",
                  "Cambios y Devoluciones",
                  "Descuentos y Financiacion",
                  "Medios de Pago",
                  "Metodos de Entrega",
                  "Legales",
                  "Oferta y Catalogos",
                  "Terminos y Condiciones",
                ].map((text, index) => (
                  <Box key={index} onClick={handleWhatsAppClick}>
                    <Typography>{text}</Typography>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6">Síguenos</Typography>
            <IconButton
              href="https://facebook.com"
              target="_blank"
              color="inherit"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/webprofitdesign/?next=%2Foauth%2Fauthorize%2F%3Fredirect_uri%3Dhttps%3A%2F%2Fbusiness.facebook.com%2Fbusiness%2Floginpage%2Figoauth%2Fcallback%2F%26app_id%3D532380490911317%26response_type%3Dcode%26state%3D%257B%2522user_nonce%2522%3A%2522ATAdbz4j8qIGeIsRR6XUctXJINJnno3h0qqDJs4sW8MjwuzDqS22D1OCg_wluwm9SaNxX0R7JcFAznSCBaubNVJROxzy4x0_taFdNh1t%2522%2C%2522from_ig_login_upsell_sso%2522%3Afalse%2C%2522login_source%2522%3A%2522creator_studio%2522%257D%26logger_id%3D3fc99216-945b-47b1-96c9-c484988444de"
              target="_blank"
              color="inherit"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/webprofitdesign/?next=%2Foauth%2Fauthorize%2F%3Fredirect_uri%3Dhttps%3A%2F%2Fbusiness.facebook.com%2Fbusiness%2Floginpage%2Figoauth%2Fcallback%2F%26app_id%3D532380490911317%26response_type%3Dcode%26state%3D%257B%2522user_nonce%2522%3A%2522ATAdbz4j8qIGeIsRR6XUctXJINJnno3h0qqDJs4sW8MjwuzDqS22D1OCg_wluwm9SaNxX0R7JcFAznSCBaubNVJROxzy4x0_taFdNh1t%2522%2C%2522from_ig_login_upsell_sso%2522%3Afalse%2C%2522login_source%2522%3A%2522creator_studio%2522%257D%26logger_id%3D3fc99216-945b-47b1-96c9-c484988444de"
              target="_blank"
              color="inherit"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              href="https://www.youtube.com/channel/UCPJhRniV-qyG0hNxXEiJnkQ"
              target="_blank"
              color="inherit"
            >
              <YouTubeIcon />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/webprofitdesign/?next=%2Foauth%2Fauthorize%2F%3Fredirect_uri%3Dhttps%3A%2F%2Fbusiness.facebook.com%2Fbusiness%2Floginpage%2Figoauth%2Fcallback%2F%26app_id%3D532380490911317%26response_type%3Dcode%26state%3D%257B%2522user_nonce%2522%3A%2522ATAdbz4j8qIGeIsRR6XUctXJINJnno3h0qqDJs4sW8MjwuzDqS22D1OCg_wluwm9SaNxX0R7JcFAznSCBaubNVJROxzy4x0_taFdNh1t%2522%2C%2522from_ig_login_upsell_sso%2522%3Afalse%2C%2522login_source%2522%3A%2522creator_studio%2522%257D%26logger_id%3D3fc99216-945b-47b1-96c9-c484988444de"
              target="_blank"
              color="inherit"
            >
              Tiktok
            </IconButton>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6">Medios de Pago</Typography>
            <IconButton color="inherit">
              <VisaIcon />
              Visa
            </IconButton>
            <IconButton color="inherit">
              <MasterCardIcon />
              Mastercard
            </IconButton>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6">Atención al Cliente</Typography>
            <Typography>+542604206967 - Gianfranco </Typography>
            <Typography>Mendoza - San Rafael</Typography>
            <Typography>Buenos Aires - Palermo</Typography>
            <IconButton color="inherit">
              <HeadsetMicIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Divider sx={{ margin: "2rem 0", backgroundColor: "white" }} />
        <Link
          href="https://webprofitlandingpage.onrender.com/"
          color="secondary"
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography variant="body2" align="center" sx={{ color: "#fff" }}>
            &copy; 2024 Powered By Webprofit
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

export default Footer;
