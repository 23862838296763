import { Grid } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GlobeIcon from "@mui/icons-material/Public";
import FavoriteIcon from "@mui/icons-material/Favorite";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DescriptionIcon from "@mui/icons-material/Description";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BarChartIcon from "@mui/icons-material/BarChart";

import CampaignIcon from "@mui/icons-material/Campaign";
import { Card, CardContent, Typography, Box } from "@mui/material";

const CustomCard = () => {
  return (
    <Box className="containerSection">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Card
            sx={{
              padding: "12%",
              maxWidth: "100%",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <VisibilityIcon fontSize="large" sx={{ color: "#2C3E50" }} />
            </Box>
            <CardContent>
              <Typography
                variant="body2"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                - Presencia Online -
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                Hazte{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  Visible
                </span>{" "}
                en la web
              </Typography>
              <Typography variant="body2" align="center" color="#2C3E50">
                Con una sólida presencia online, tu negocio estará disponible
                para tus clientes las{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  24 horas del día, los 7 días de la semana
                </span>
                , sin importar la ubicación o la hora.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Card
            sx={{
              padding: "12%",
              maxWidth: "100%",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <GlobeIcon fontSize="large" sx={{ color: "#2C3E50" }} />
            </Box>
            <CardContent>
              <Typography
                variant="body2"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                - Alcance Global -
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  Conquista{" "}
                </span>
                nuevos horizontes
              </Typography>
              <Typography variant="body2" align="center" color="#2C3E50">
                Amplía tu base de clientes más allá de las fronteras locales y
                llega a audiencias a nivel nacional e
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Internacional{" "}
                </span>
                , abriendo nuevas oportunidades de crecimiento.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Card
            sx={{
              padding: "12%",
              maxWidth: "100%",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <CampaignIcon fontSize="large" sx={{ color: "#2C3E50" }} />
            </Box>
            <CardContent>
              <Typography
                variant="body2"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                - Marketing y Promoción -
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                Haz que
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Tu Marca Brille{" "}
                </span>
                en línea
              </Typography>
              <Typography variant="body2" align="center" color="#2C3E50">
                Aprovecha las herramientas de marketing digital para{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Promocionar{" "}
                </span>
                tus productos o servicios de manera{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Efectiva{" "}
                </span>
                , aumentando la visibilidad y el reconocimiento de tu marca.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Card
            sx={{
              padding: "12%",
              maxWidth: "100%",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <VerifiedUserIcon fontSize="large" sx={{ color: "#2C3E50" }} />
            </Box>
            <CardContent>
              <Typography
                variant="body2"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                - Credibilidad y Profesionalismo -
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                Transmite{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Confianza{" "}
                </span>{" "}
                desde el primer clic
              </Typography>
              <Typography variant="body2" align="center" color="#2C3E50">
                Una página web bien diseñada y funcional ayuda a establecer
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Credibilidad{" "}
                </span>{" "}
                y{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Confianza{" "}
                </span>
                en tu negocio, mostrando tu profesionalismo y compromiso con la{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Calidad
                </span>
                .
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Card
            sx={{
              padding: "12%",
              maxWidth: "100%",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <DescriptionIcon fontSize="large" sx={{ color: "#2C3E50" }} />
            </Box>
            <CardContent>
              <Typography
                variant="body2"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                - Información Accesible -
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                Todo lo que{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Necesitas
                </span>
                , a solo un clic de distancia
              </Typography>
              <Typography variant="body2" align="center" color="#2C3E50">
                Proporciona a tus clientes acceso{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Facil
                </span>{" "}
                y{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Rápido
                </span>{" "}
                a toda la información que necesitan sobre tus{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Productos
                </span>
                ,{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Servicios
                </span>
                ,{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Horarios
                </span>{" "}
                y{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Ubicación
                </span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Card
            sx={{
              padding: "12%",
              maxWidth: "100%",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <ShoppingCartIcon fontSize="large" sx={{ color: "#2C3E50" }} />
            </Box>
            <CardContent>
              <Typography
                variant="body2"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                - Facilita las Ventas -
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                Haz que comprar sea un{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Placer
                </span>
              </Typography>
              <Typography variant="body2" align="center" color="#2C3E50">
                Simplifica el proceso de{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Compra{" "}
                </span>{" "}
                para tus clientes con una página web bien estructurada y un
                proceso de compra claro, aumentando la{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Satisfaccion{" "}
                </span>{" "}
                y la conversión.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Card
            sx={{
              padding: "12%",
              maxWidth: "100%",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <FavoriteIcon fontSize="large" sx={{ color: "#2C3E50" }} />
            </Box>
            <CardContent>
              <Typography
                variant="body2"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                - Interacción con Clientes -
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="#2C3E50"
                gutterBottom
              >
                Conéctate de manera personal y{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Efectiva
                </span>
              </Typography>
              <Typography variant="body2" align="center" color="#2C3E50">
                Fomenta la interacción directa y personalizada con tus clientes
                a través de formularios de{" "}
                <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                  {" "}
                  Contacto
                </span>
                , chat en vivo y redes sociales integradas, mejorando la
                satisfacción del cliente y la lealtad a la marca.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
        <Card
          sx={{
            padding: "12%",
            maxWidth: "100%",
            backgroundColor: "transparent",
            boxShadow: "none",

          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
            <BarChartIcon fontSize="large" sx={{ color: "#2C3E50" }} />
          </Box>
          <CardContent>
            <Typography
              variant="body2"
              align="center"
              color="#2C3E50"
              gutterBottom
            >
              - Análisis y Seguimiento -
            </Typography>
            <Typography variant="h5" align="center" color="#2C3E50" gutterBottom>
              Datos que <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                {" "}
                Impulsan
              </span> decisiones inteligentes
            </Typography>
            <Typography variant="body2" align="center" color="#2C3E50">
              Utiliza herramientas de <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                {" "}
                análisis web
              </span> para monitorear el
              rendimiento de tu sitio y tomar <span style={{ color: "#FF5733", fontWeight: "bold" }}>
                {" "}
                decisiones informadas
              </span>  para mejorar
              continuamente tu presencia online.
            </Typography>
          </CardContent>
        </Card>
        </Grid>
            
        {/* Agrega aquí las otras tarjetas con el mismo formato */}
      </Grid>
    </Box>
  );
};

export default CustomCard;
