import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "./index.css";
import HomePage from "./pages/HomePage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF5733",
    },
    secondary: {
      main: "#000000",
    },
    success: {
      main: "#018E42",
    },
    white: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0, // Extra small (teléfonos pequeños)
      sm: 600, // Small (teléfonos y tablets en orientación vertical)
      md: 960, // Medium (tablets en orientación horizontal y algunos dispositivos en orientación vertical)
      lg: 1280, // Large (pantallas de escritorio y algunos dispositivos en orientación horizontal)
      xl: 1920, // Extra large (pantallas grandes y extra grandes)
    },
  },

  // Aplica el fondo en el tema
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#000000",
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
